/*
Author: Traff
Version: 1.0.1
Creation: 2023-10-12
Description: 封装axios
Version Log：
1.0.1 初始版本
 */

import axios from "axios";
import router from "@/router";
import {check_token} from "@/utils/check_token";
import {backend_url} from "@/utils/config";


let instance = axios.create({
    baseURL: backend_url,
    //跨域支持
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: true
})

//请求拦截器
instance.interceptors.request.use(function (config){
    //检查本地access_token，如果存在就向请求的header里写入token
    const access_token = localStorage.getItem('access_token');
        //console.log('拦截器启动')
    if (access_token) {
        config.headers.Authorization = `jwt ${access_token}`
    }
    return config
}, function (error) {
    return Promise.reject(error)
    }
)

//响应拦截器，Token过期处理
instance.interceptors.response.use(function (response) {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
        check_token()
    }
    return response
    }, async function (error) {
        if (error.response.status === 401) {
            //401 说明 2 小时内客户没有重新等里，删除本地access_token
            localStorage.removeItem('access_token')
            alert('Token失效，请重新登录。（Axios拦截）')
            await router.push('/')
        } else {
            return Promise.reject(error)
        }
    }
)

/* 相应拦截器备份
instance.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('access_token')
        alert('Token失效，请重新登录。')
        await router.push('/')
    } else {
        return Promise.reject(error)
    }
}
)

 */

// 封装get请求
export function get(url, params){
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params
        })
            .then(response => {
                resolve(response);
            }).catch(error => {
            reject(error)
        })

    })
}

// 封装post请求
export function post(url, params, config){
    return new Promise((resolve, reject) => {
        instance.post(url, params, config)
            .then(response => {
                resolve(response)
            }).catch(error => {
            reject(error)
        })
    })
}
