<template>
  <router-view/>
  <!--
  <login></login>
  -->

</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import {defineComponent} from "vue";
//import Login from "@/components/login.vue";


//export default defineComponent({
  //components: {Login}
//})



</script>

<style>
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f8f8f8;
}
</style>
