//定义关于user的store
import {defineStore} from 'pinia'

const useUser = defineStore("user",{
    state:() => ({
        uid: "",
        user_name: "",
        nick_name: "",
        access_token: "",
        is_login: false,
        update_token: false,
    }),

    getters: {

    },

    actions: {

    }
})

export default useUser
