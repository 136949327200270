import { createRouter, createWebHistory } from 'vue-router'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {env} from "@/utils/config";
import {check_token} from "@/utils/check_token";

Nprogress.configure({ showSpinner: true });

// 路由地址配置
const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/components/login'),
        meta: {
            checkAuth: true,
            component_str: 'login'
        }
    },
    {
        path: '/redirect',
        name: 'Redirect',
        component: () => import('@/components/Redirect'),
        meta: {
            checkAuth: false,
            component_str: 'Redirect'
        }
    },
    {
        path: '/workbench',
        name: 'Workbench',
        component: () => import('@/components/Workbench'),
        meta: {
            checkAuth: true,
            component_str: 'Workbench'
        }
    },

]

// 添加到创建的路由中
const router = createRouter({
    history: createWebHistory(),
    routes,
})


// 给路由定义前置的全局守卫
router.beforeEach(async (to, from, next) => {
    Nprogress.start();
    document.title = '六纲内部管理平台-'+env

    if (to.meta.checkAuth) {
        let access_token = localStorage.getItem('access_token')
        let exp = localStorage.getItem('access_token_expire')
        if (exp && access_token) {
            let now = new Date().getTime()
            if (now < exp) {
                if(to.path === '/'){
                    next('/workbench');
                }else{
                    next();
                }
            } else {
                if(to.path === '/'){
                    next();
                }else{
                    alert('Token过期，请重新登录。(Router拦截)')
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('access_token_expire')
                    next('/')
                }
            }
        } else {
            if(to.path === '/'){
                next();
            }else{
                next('/')
            }
        }
    }
    else {
        next();
    }
});

router.afterEach(() =>{
    Nprogress.done();
});


export default router
