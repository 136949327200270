/*
Author: Traff
Version: 1.0.1
Creation: 2023-10-2
Description: 前后端对称加密传输，后端数据存储另行加密
Version Log：
1.0.1 初始版本
 */

let CryptoJs = require('crypto-js');

let key = CryptoJs.enc.Utf8.parse('3n$9r~5fwGNHJa;4x/8R?m0qS1YbCD+6');
let iv = CryptoJs.enc.Utf8.parse('6-Roles');

// 加密部分
function Encrypt(str) {
    let strhex = CryptoJs.enc.Utf8.parse(str)
    let encrypted = CryptoJs.AES.encrypt(strhex,key,{
        iv:iv,
        mode: CryptoJs.mode.CBC,
        padding: CryptoJs.pad.Pkcs7,
    });
    //返回base64格式结果
    return encrypted.ciphertext.toString();
}

// 解密部分
function Decrypt(str) {
    let strhex = CryptoJs.enc.Hex.parse(str);
    let strb64 = CryptoJs.enc.Base64.stringify(strhex);
    let decrypted = CryptoJs.AES.decrypt(strb64,key,{
        iv:iv,
        mode: CryptoJs.mode.CBC,
        padding: CryptoJs.pad.Pkcs7,
    });
    let destr = CryptoJs.enc.Utf8.stringify(decrypted);
    //返回Json
    //return JSON.parse(destr);
    return destr.toString();
}

export {
    Encrypt,
    Decrypt,
}
