import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App)

//Bootstrap 5 import
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.min.css'


//引入自定义 CSS
import '@/assets/css/main.6r.css'
//全局引用封装axios
import {get,post} from '@/utils/request'
//全局引用加密解密JS
import {Encrypt,Decrypt} from '@/assets/js/aescrypto.js'
//引入store中定义的pinia
//import { createPinia } from 'pinia'
import pinia from "@/store"
//全局后台地址配置
import { backend_url } from "@/utils/config";

app.config.globalProperties.$aes = {Decrypt,Encrypt}
app.config.globalProperties.$axios = {get,post}
app.config.globalProperties.$backend_url = backend_url

// 防止页面刷新动态路由丢失
if (sessionStorage.getItem('dynamic_router')) {
    let router_list = JSON.parse(sessionStorage.getItem('dynamic_router'))
    router_list.forEach((item, i, arr) =>{
        if (! router.hasRoute(item.name)) {
            router.addRoute({
                path: item.path,
                name: item.name,
                component: () => import('@/components/'+item.meta.component_str),
                meta: item.meta
            })
        }
    })
}


app.use(router).use(pinia).mount('#app')
