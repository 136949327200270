import axios from "axios";
import useUser from "@/store/user";
import { Encrypt,Decrypt } from "@/assets/js/aescrypto"
import {backend_url} from "@/utils/config";

export function check_token() {
    let access_token = localStorage.getItem('access_token')
    //console.log('检查Token...')
    if (access_token) {
        const userStore = useUser()
        let post_data = {'access_token': access_token}
        //加密拋送数据
        post_data = {content: Encrypt(JSON.stringify(post_data))}
        // 不使用封装后的axios,避免拦截引起的无限循环
        axios
            .post(backend_url+'/api/logintoken', post_data)
            .then( (response)=> {
                if (response) {
                    let res = JSON.parse(Decrypt(response.data))
                    // 后台返回 90001，标识 Token 效期到30分钟以下，需要更新
                    if (res['token_code'] === 90001) {
                        console.log('Token有效期接近，自动更新...')
                        let access_token = res['new_token']
                        if (access_token) {
                            localStorage.setItem('access_token', access_token)
                            let exp_date = new Date().getTime()
                            exp_date = exp_date + 2*60*60*1000
                            localStorage.setItem('access_token_expire', exp_date.toString())
                        }
                        // 如果返回 40001（Redis的Token已经失效），则删除本地access_token
                    } else if (res['token_code'] === 40001) {
                        console.log('Token已经失效')
                        localStorage.removeItem('access_token')
                        //userStore.$reset()
                    }
                    // 写入userStore
                    if (res['user'] !== '') {
                        let user = JSON.parse(res['user'])
                        // pinia user模块uid数据为‘’,则重新写入用户信息
                        if (user !== '' && userStore.uid === '') {
                            console.log('刷新用户信息...')
                            userStore.uid = user['uid']
                            userStore.user_name = user['user_name']
                            userStore.nick_name = user['nick_name']
                            userStore.is_login = true
                            userStore.access_token = access_token
                            //return userStore
                        }
                    }

                }
            })
            .catch(error=>{
                console.log('更新Token失败:'+error)
                //localStorage.removeItem('access_token')
                //userStore.$reset()
            })
    }
}


